
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      assessorSelected: [],
      assessor_ids: [],
      select_assessor_ids: [],
      assessorInfo: [
        {
          id: 1,
          name: "Zohirul Alam Tiemoon",
        },
        {
          id: 2,
          name: "Md. Nahidur Rahman Rifath",
        },
        {
          id: 3,
          name: "Md Abdul Alim",
        },
      ],
      loading: false,
      assessorInfoData: false,
      showCourseNotice: false,
      showAssessorList: false,
      showMapAssessorList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    selectedAssessor() {
      this.assessor_ids.map((item) => {
        var check = 0;
        this.assessorSelected.map((s_item) => {
          if (s_item['id'] == item['id']) {
            check = 1;
          }
         });

         for( var i = 0; i < this.assessorInfo.length; i++){ 
            if (this.assessorInfo[i]['id'] === item['id']) { 
                this.assessorInfo.splice(i, 1); 
            }
        }
         if(check == 0){
          this.assessorSelected.push(item);
         }
      });
    },
    unselectedAssessor() {
      this.select_assessor_ids.map((item) => {
        var check = 0;
        this.assessorInfo.map((s_item) => {
          if (s_item['id'] == item['id']) {
            check = 1;
          }
         });

         for( var i = 0; i < this.assessorSelected.length; i++){ 
            if (this.assessorSelected[i]['id'] === item['id']) { 
                this.assessorSelected.splice(i, 1); 
            }
        }
         if(check == 0){
          this.assessorInfo.push(item);
         }
      });
    },
    selectedAllAssessor() {
      Array.prototype.push.apply(this.assessorSelected,  this.assessorInfo);
      this.assessorInfo.splice(0);
    },
    unselectedAllAssessor() {
      Array.prototype.push.apply(this.assessorInfo,  this.assessorSelected);
      this.assessorSelected.splice(0);
    },
    assessorInfo() {
      this.assessorInfoData = true;
    },
    assessorList() {
      this.showAssessorList = true;
    },
    assessorMapList() {
      this.showMapAssessorList = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
